<template>
  <v-snackbar :color="color" v-model="show" :timeout="timeout">
    {{ text }}
    <v-btn text @click="show = false">Ok</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      timeout: 10000,
      color: 'error',
      text: ''
    };
  },
  computed: {
    snackbarColor() {
      return this.$store.state.notifications.snackbarColor;
    },
    snackbarText() {
      return this.$store.state.notifications.snackbarText;
    }
  },
  watch: {
    snackbarText(text) {
      if (text) {
        this.text = this.snackbarText;
        this.show = true;
        this.$store.commit('notifications/SET_SNACKBAR_TEXT', '');
      }
    },
    snackbarColor(color) {
      if (color) {
        this.color = this.snackbarColor;
        this.show = true;
        this.$store.commit('notifications/SET_SNACKBAR_COLOR', '');
      }
    }
  }
};
</script>
